import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { HStack } from '@chakra-ui/layout';
import {
  Box,
  Divider,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Textarea } from '@chakra-ui/textarea';
import { useForm } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import trackingConsts from '../../../config/tracking';
import TYPES from '../../../config/types';
import useTracking from '../../../logic/hooks/useTracking';
import useStore from '../../../logic/zustand';

interface IProps {
  isLoading?: boolean;
  isEditing?: boolean;
  onAdd?: (
    params: { [K: string]: string },
    reset,
    values: { [K: string]: string }
  ) => void;
  onEdit?: (params: { [K: string]: string }) => void;
  editing?: boolean;
  values?: { [K: string]: string };
  onCancel?: () => void;
  isTitleReadOnly?: boolean;
}

const EnvVarForm = (props: IProps) => {
  const {
    onAdd,
    editing = false,
    isLoading,
    values: envVarValues,
    onCancel,
    onEdit,
    isTitleReadOnly,
    isEditing,
  } = props;
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('white', 'gray.700');
  const labelColor = useColorModeValue('gray.800', 'gray.200');
  const formInputColor = useColorModeValue('white', 'gray.900');

  const { openModal, setSelect } = useStore((store) => ({
    openModal: store['modal/open'],
    setSelect: store.setSelect,
  }));

  const values = {
    name: envVarValues?.name || '',
    development: envVarValues?.development || '',
    production: envVarValues?.production || '',
  };

  const handleDelete = (name) => {
    setSelect(TYPES.SELECT.ENV_VAR_NAME, values?.name);
    openModal({ type: TYPES.MODALS.DELETE_ENV_VAR });
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: values,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { track } = useTracking();

  const onSubmit = (formValues) => {
    if (editing) {
      onEdit(formValues);
    } else {
      track(trackingConsts.FLOWS.ADDED_ENVIRONMENT_VARIABLE, {
        name: formValues.name,
      });
      onAdd(formValues, reset, values);
    }
  };

  const handleEnvVarLinkClick = () => {
    track(trackingConsts.SETTINGS.OPENED_ENVIRONMENT_VARIABLE_DOCS);
    window.open(TYPES.DOCS.ENVIRONMENT_VARIABLES, '_blank');
  };

  return (
    <Box
      w="100%"
      bg={bgColor}
      p="5"
      borderColor={borderColor}
      borderWidth="1px"
      borderRadius="md"
    >
      <form onSubmit={handleSubmit(onSubmit)} name="envVarForm">
        <Stack spacing={8}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel fontSize="sm" htmlFor="name" color={labelColor}>
              NAME
            </FormLabel>
            <Input
              isDisabled={editing}
              bg={formInputColor}
              id="name"
              isReadOnly={isTitleReadOnly}
              placeholder="EXAMPLE_NAME"
              py="6"
              {...register('name', {
                required: 'This is required',
              })}
              defaultValue={values?.name}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>
          <HStack align="start" spacing="5">
            <FormControl isInvalid={!!errors.development}>
              <FormLabel fontSize="sm" htmlFor="development" color={labelColor}>
                DEVELOPMENT VALUE
              </FormLabel>
              <Textarea
                bg={formInputColor}
                id="development"
                placeholder="f849db1376c4"
                {...register('development', {
                  required: 'This is required',
                })}
                defaultValue={values?.development}
              />
              <FormErrorMessage>
                {errors.development && errors.development.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.production}>
              <FormLabel fontSize="sm" htmlFor="production" color={labelColor}>
                PRODUCTION VALUE
              </FormLabel>
              <Textarea
                bg={formInputColor}
                id="production"
                placeholder="96ee7124ff58"
                {...register('production', {
                  required: 'This is required',
                })}
                defaultValue={values?.production}
              />
              <FormErrorMessage>
                {errors.production && errors.production.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <Divider />
          {!editing && (
            <HStack justify="space-between">
              <Text>
                Learn more about{' '}
                <Button
                  onClick={handleEnvVarLinkClick}
                  variant="link"
                  colorScheme="blue"
                  rightIcon={<FiExternalLink />}
                >
                  Environment Variables
                </Button>
              </Text>
              <Button
                disabled={!isValid}
                isLoading={isLoading}
                bg="blue.500"
                colorScheme="blue"
                variant="solid"
                fontWeight="600"
                type="submit"
              >
                Add
              </Button>
            </HStack>
          )}
          {editing && (
            <HStack justify="space-between">
              <Button onClick={handleDelete} colorScheme="red" variant="solid">
                Remove
              </Button>
              <HStack spacing="5">
                <Button
                  onClick={() => onCancel()}
                  variant="ghost"
                  fontWeight="600"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  isLoading={isEditing}
                  bg="blue.500"
                  colorScheme="blue"
                  variant="solid"
                  fontWeight="600"
                  type="submit"
                >
                  Save
                </Button>
              </HStack>
            </HStack>
          )}
        </Stack>
      </form>
    </Box>
  );
};

EnvVarForm.defaultProps = {
  isLoading: false,
  isEditing: false,
  editing: false,
  values: {
    name: '',
    development: '',
    production: '',
  },
  onAdd: undefined,
  onEdit: undefined,
  onCancel: undefined,
  isTitleReadOnly: false,
};

export default EnvVarForm;
