/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic';
import { ImMagicWand } from 'react-icons/im';
import { FaShapes } from 'react-icons/fa';
import {
  Box,
  Flex,
  Stack,
  useColorMode,
  useColorModeValue as mode,
  useColorModeValue
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { AiFillHome } from 'react-icons/ai';
import { VscSymbolVariable } from 'react-icons/vsc';
import { BsFillFileTextFill } from 'react-icons/bs';
import useTracking from '../../../logic/hooks/useTracking'; //'libs/components/src/logic/hooks/useTracking';
import { MobileMenuButton } from '../MobileMenuButton';
import { NavItem } from '../NavItem';
import { useMobileMenuState } from '../useMobileMenuState';
import { UserProfile } from '../UserProfile';
import TYPES from '../../../config/types';
import SearchInput from './SearchInput';
import useStore from '../../../logic/zustand';
import trackingConsts from "../../../config/tracking"; //'libs/components/src/config/tracking';

const CreateRecipeButton = dynamic(() => import('../../shared/CreateRecipeButton'), { ssr: false });

const SearchLayout = ({ children }) => {
  const { track } = useTracking();
  const borderColor = useColorModeValue('gray.50', 'gray.800');
  const bgColor = useColorModeValue('white', 'black');
  const router = useRouter();

  const { setUserInputs } = useStore((store) => ({
    setUserInputs: store.setUserInputs
  }));

  useEffect(() => {
    setUserInputs(TYPES.USER_INPUTS.SEARCH_TERM, null);
  }, []);

  const { isOpen, toggle } = useMobileMenuState();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex overflowY="auto" direction="column">
      <Box overflow="hidden" sx={{ '--sidebar-width': '16rem' }}>
        <Box
          bg={bgColor}
          h="full"
          as="nav"
          display="block"
          flex="1"
          width="var(--sidebar-width)"
          left="0"
          py="5"
          px="3"
          color={mode('gray.800', 'gray.50')}
          position="fixed"
          borderRightColor={borderColor}
          borderRightWidth="1px"
        >
          <Box h="100%" fontSize="sm" lineHeight="tall">
            <Flex position="relative" h="full" direction="column">
              <Box>
                <UserProfile />
                <Stack spacing="8" flex="1" overflow="auto" pt="8">
                  <CreateRecipeButton onClick={() => track(trackingConsts.DASHBOARD_SIDEBAR.CLICKED_CREATE_FLOW)} />
                  <Stack spacing="1">
                  <NavItem
                      active={
                        router.pathname === TYPES.ROUTES.HOME
                      }
                      href={TYPES.ROUTES.HOME}
                      icon={<AiFillHome/>}
                      label="Home"
                    />
                  <NavItem
                      active={
                        router.pathname === TYPES.ROUTES.SEARCH
                      }
                      href={TYPES.ROUTES.SEARCH}
                      icon={<SearchIcon  />}
                      label="Search"
                    />
                    <NavItem
                    active={router.pathname === TYPES.ROUTES.RECIPES}
                      href={TYPES.ROUTES.RECIPES}
                      icon={<ImMagicWand  />}
                      label="Flows"
                    />
                    <NavItem
                    active={!!(router.pathname === '/' || router.pathname.includes(TYPES.PATHNAME.MICROSERVICE))}
                      href={TYPES.ROUTES.MICROSERVICES}
                      icon={<FaShapes  />}
                      label="Datasources"
                    />
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <Stack
                  w="100%"
                  bottom="0px"
                  position="absolute"
                  spacing="1"
                >
                   <NavItem
                    href={TYPES.ROUTES.LOGS}
                    active={router.pathname === TYPES.ROUTES.LOGS}
                    icon={<BsFillFileTextFill color="white" />}
                    label="Logs"
                  />
                  <NavItem
                    href={TYPES.ROUTES.ENV_VARS}
                    icon={<VscSymbolVariable color="white" />}
                    label="Environment Variables"
                  />
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box
          flex="1"
          h="100%"
          p={{ base: '0', md: '0' }}
          marginStart={{ md: 'var(--sidebar-width)' }}
          position="relative"
          left={isOpen ? 'var(--sidebar-width)' : '0'}
          transition="left 0.2s"
        >
          <Box maxW="2560px" bg={mode('white', 'black')} minH="100vh">
            <Flex direction="column" overflow="auto" height="full">
              <Flex
                px={{ base: '5', md: '10' }}
                borderBottomWidth="1px"
                borderBottomColor={borderColor}
                w="full"
                py="4"
                justify="space-between"
                align="center"
              >
                <Flex w="100%"  align="center" minH="8">
                  <MobileMenuButton onClick={toggle} isOpen={isOpen} />
                  <SearchInput/>
                </Flex>
              </Flex>
              <Box>{children}</Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default SearchLayout;
