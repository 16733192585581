import useGetUserFlow from '../flows/useGetUser';
import getEnvFromHost from '../utils/getEnvFromHost';

const env = getEnvFromHost() === 'production' ? 'live' : 'test';
const baseurl = `https://api.buildable.dev/flow/v1/call/${env}/segment-rest-37e717ebca/`;

export default function useTracking() {
  const { data: user } = useGetUserFlow();

  function makeContext() {
    let context = {};
    try {
      const {
        location: { pathname: path = '', search = '', href: url = '' } = {},
      } = window || {};

      context = {
        locale: navigator?.language,
        page: {
          path,
          search,
          title: document?.title,
          url,
        },
        userAgent: navigator?.userAgent,
      };
    } catch (ex) {
      // just eat the error. this happens if an
      // event is fired server side inadvertently
    }
    return context;
  }

  function request(path, data) {
    fetch(`${baseurl}${path}`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(data),
    });
  }

  function track(event, properties = {}) {
    request('t', {
      event,
      userId: user._id,
      properties,
      context: makeContext(),
    });
  }

  function identify() {
    request('i', {
      userId: user._id,
      traits: {
        name: `${user.firstName} ${user.lastName}`,
      },
      context: makeContext(),
    });
  }

  return {
    track,
    identify,
  };
}
