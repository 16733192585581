import {
  Box,
  Flex,
  Stack,
  useColorModeValue,
  useColorModeValue as mode,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NavItem } from '../NavItem';
import { useMobileMenuState } from '../useMobileMenuState';
import useStore from '../../../logic/zustand';
import TYPES from '../../../config/types';
import { MobileMenuButton } from '../MobileMenuButton';

const activeSettingsTab = (pathname: string) => {
  const [key] = Object.entries(TYPES.ROUTES).find(([k, v]) => pathname === v);
  return TYPES.SETTINGS_TYPES[key]
};

const SettingsLayout = ({ children }) => {
  const borderColor = useColorModeValue('gray.50', 'gray.800');
  const bgColor = useColorModeValue('white', 'black');
  const { isOpen, toggle } = useMobileMenuState();

  const initState = {};
  const router = useRouter();

  const [state, setState] = useState({
    [TYPES.SETTINGS_TYPES.PROFILE]: true,
  });

  useEffect(() => {
    const activeState = activeSettingsTab(router.pathname);
    setState({
      ...initState,
      [activeState]: true,
    });
  }, [router.pathname]);

  const { setSelect } = useStore((store) => ({
    setSelect: store.setSelect,
  }));

  const handleClick = (route, settingType) => {
    setState({ ...initState, [settingType]: true });
    router.push(route);
  };

  return (
    <Flex overflowY="auto" direction="column">
      <Box overflow="hidden" sx={{ '--sidebar-width': '16rem' }}>
        <Box
          bg={bgColor}
          h="full"
          as="nav"
          display="block"
          flex="1"
          width="var(--sidebar-width)"
          left="0"
          py="5"
          px="3"
          color={mode('gray.800', 'gray.50')}
          position="fixed"
          borderRightColor={borderColor}
          borderRightWidth="1px"
        >
          <Box h="100%" fontSize="sm" lineHeight="tall">
            <Flex position="relative" h="full" direction="column">
              <Flex
                cursor="pointer"
                onClick={() => {
                  router.push(TYPES.ROUTES.MICROSERVICES, undefined, {
                    shallow: true,
                  });
                }}
                justify="start"
                align="center"
              >
                <ChevronLeftIcon
                  color={mode('gray.800', 'gray.100')}
                  fontSize="xl"
                />
                <Text
                  fontWeight="bold"
                  color={mode('gray.800', 'gray.100')}
                  ml={2}
                  size="md"
                >
                  Settings
                </Text>
              </Flex>

              <Stack spacing="1" flex="1" overflow="auto" pt="10">
                <Flex
                  onClick={() =>
                    handleClick(
                      TYPES.ROUTES.PROFILE,
                      TYPES.SETTINGS_TYPES.PROFILE
                    )
                  }
                >
                  <NavItem
                    active={state[TYPES.SETTINGS_TYPES.PROFILE]}
                    label="Profile"
                  />
                </Flex>
                <Flex
                  onClick={() =>
                    handleClick(
                      TYPES.ROUTES.PREFERENCES,
                      TYPES.SETTINGS_TYPES.PREFERENCES
                    )
                  }
                >
                  <NavItem
                    active={state[TYPES.SETTINGS_TYPES.PREFERENCES]}
                    label="Preferences"
                  />
                </Flex>
                <Flex
                  onClick={() =>
                    handleClick(
                      TYPES.ROUTES.ENV_VARS,
                      TYPES.SETTINGS_TYPES.ENV_VARS
                    )
                  }
                >
                  <NavItem
                    active={state[TYPES.SETTINGS_TYPES.ENV_VARS]}
                    label="Environment Variables"
                  />
                </Flex>
              </Stack>
            </Flex>
          </Box>
        </Box>
        <Box
          flex="1"
          h="100%"
          p={{ base: '0', md: '0' }}
          marginStart={{ md: 'var(--sidebar-width)' }}
          position="relative"
          left={isOpen ? 'var(--sidebar-width)' : '0'}
          transition="left 0.2s"
        >
          <Box w="100%" align="center" bg={mode('white', 'black')} minH="100vh">
            <Flex
              align="start"
              maxW="640px"
              w="100%"
              direction="column"
              overflow="auto"
              height="full"
            >
              <Flex
                w="full"
                align="start"
                pt={{ base: 5, md: 0 }}
                px={{ base: 5, md: 0 }}
              >
                <MobileMenuButton onClick={toggle} isOpen={isOpen} />
              </Flex>
              <Box align="start" w="full" px={5} py={{ base: 5, md: 20 }}>
                {children}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default SettingsLayout;
