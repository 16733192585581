const trackingConsts = {
  DASHBOARD_SIDEBAR: {
    CLICKED_CREATE_FLOW: 'Clicked Create Flow',
    CREATED_FLOW: 'Created Flow',
    CLICKED_TAB: 'Clicked Tab',
    CLICKED_NAV_MENU: 'Clicked Nav Menu',
    CLICKED_NAV_MENU_TAB: 'Clicked Nav Menu Tab',
  },
  SETTINGS: {
    UPDATED_PROFILE: 'Updated Profile',
    UPDATED_PASSWORD: 'Updated Password',
    ADDED_ENVIRONMENT_VARIABLE: 'Added Environment Variable',
    CONFIRMED_DELETE_ENVIRONMENT_VARIABLE:
      'Confirmed Delete Environment Variable',
    UPDATED_ENVIRONMENT_VARIABLE: 'Updated Environment Variable',
    SEARCHED_ENVIRONMENT_VARIABLE: 'Searched Environment Variable',
    OPENED_ENVIRONMENT_VARIABLE_DOCS: 'Opened Environment Variable Docs',
  },
  HOME: {
    CLICKED_CREATE_FLOW: 'Clicked Create Flow',
    CLICKED_CARD: 'Clicked Card',
    CLICKED_COMMUNITY_ITEM: 'Clicked Community Item',
  },
  SEARCH: {
    SEARCHED_FLOW: 'Searched Flow',
  },
  FLOWS: {
    OPENED_FLOW: 'Opened Flow',
    CLICKED_EXISTING_TAG: 'Clicked Existing Tag',
    EDITED_TAG: 'Edited Tag',
    FORKED_FLOW: 'Forked Flow',
    CONFIRMED_DELETE_FLOW: 'Confirmed Delete Flow',
    ADDED_ENVIRONMENT_VARIABLE: 'Added Environment Variable',
  },

  MICROSERVICES: {
    CLICKED_MICROSERVICE: 'Clicked Microservice',
    SELECTED_OPTION: 'Selected Option',
    TESTED_CONNECTION: 'Tested Connection',
    CONNECTED_MICROSERVICE: 'Connected Microservice',
    SYNCED_MICROSERVICE: 'Synced Microservice',
    CONFIRMED_DELETE_MICROSERVICE: 'Confirmed Delete Microservice',
    CLICKED_SHOW_DETAILS: 'Clicked Show Details',
    CLICKED_COLLECTION: 'Clicked Collection',
    ADDED_NEW_COLLECTION: 'Added New Collection',
    CONFIRMED_DELETE_COLLECTION: 'Confirmed Delete Collection',
    RAN_QUERY: 'Ran Query',
    UPDATED_COLLECTION_DATA: 'Updated Collection Data',
    JOINED_MICROSERVICE_WAITLIST: 'Joined Microservice Waitlist',
  },
};

export default trackingConsts;
