import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Textarea,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useRef } from 'react';
import useForms from '../../../logic/hooks/useForms';

// Only REST triggers allowed for now
const options = [
  {
    value: 'rest',
    label: 'REST',
  },
];

const CreateFlowModal = ({
  onSubmit,
  isOpenModal,
  closeModal,
}: {
  onSubmit: (data: unknown) => Promise<void> | void;
  isOpenModal: boolean;
  closeModal: VoidFunction;
}) => {
  const size = useBreakpointValue({ md: '2xl' });
  const isCentered = useBreakpointValue({ md: true });

  const formRef = useRef<HTMLFormElement>(null);

  const { formData, onChange, resetData } = useForms({
    name: '',
    description: '',
  });

  const handleSubmit = async () => {
    await onSubmit(formData);
    resetData();
  };

  return (
    <Modal
      size={size}
      onClose={closeModal}
      isOpen={isOpenModal}
      isCentered={isCentered}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent>
        <ModalBody maxH="max-content" rounded="md" pt={12} pb={4}>
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <VStack
              spacing="4"
              w="100%"
              display="block"
              py={0}
              alignItems="center"
            >
              <FormControl isRequired>
                <Input
                  autoFocus
                  variant="filled"
                  size="lg"
                  placeholder="Flow name"
                  fontWeight="bold"
                  fontSize="2xl"
                  name="name"
                  autoComplete="off"
                  onChange={onChange}
                />
              </FormControl>
              <FormControl>
                <Textarea
                  name="description"
                  variant="filled"
                  placeholder="Description"
                  defaultValue=""
                  size="md"
                  onChange={onChange}
                  rows={4}
                />
              </FormControl>
              <FormControl id="triggerType">
                <FormLabel>Select trigger type</FormLabel>
                <Select
                  name="trigger"
                  defaultValue={options[0].value}
                  variant="filled"
                  disabled
                >
                  {options.map(({ label, value }) => (
                    <option key={`${label}.${value}`} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </form>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between" width="100%">
          <Button
            variant="link"
            colorScheme="gray"
            size="lg"
            mr={3}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (formRef.current.checkValidity()) {
                formRef.current.requestSubmit();
              }
              formRef.current.reportValidity();
            }}
            variant="solid"
            colorScheme="blue"
            type="submit"
            size="lg"
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateFlowModal;
